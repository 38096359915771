






















































































































































import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class OverallPlan extends Vue {
  arr1 = ["1-1.png", "1-2.png", "1-3.png", "1-4.png", "1-5.png"];
  categoryName = "";
  edit(item: string): void {
    this.categoryName = item;
    // this.$message.success(this.categoryName);
  }
}
